<template>
  <div>
    <RadioGroup :formName="formName" field="group" :options="[
      { text: 'Cliente', value: 1 },
      { text: 'Fornecedor', value: 2 },
    ]" v-model="paymentOs.destination" />
    <b-row>
      <b-col sm="9">
        <Select title="Tipo de Pagamento" field="typePayment" :required="true" :markFormDirty="false"
          v-model="paymentOs.typePayment" url="/api/v1/finance/type-payment/select-all" :showNewRegister="true"
          nameNewRegister="typePaymentCreateUpdate" titleNewRegister="Tipo de Pagamento" :widthNewRegister="500"
          :heightNewRegister="250">
          <TypePaymentCreateUpdate :registerInSelect="true" v-model="paymentOs.typePayment" />
        </Select>
      </b-col>
      <b-col sm="3">
        <InputDecimal title="Valor" field="value" :formName="formName" :required="true" :markFormDirty="false"
          :maxLength="5" type="float" v-model="paymentOs.value" />
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="3">
        <DateTime title="Vencimento" field="dueDate" format="DD/MM/YYYY" type="date" placeholder :formName="formName"
          :required="true" v-model="paymentOs.dueDate" />
      </b-col>
      <b-col sm="9">
        <InputText title="Descrição" field="name" :formName="formName" :required="false" :maxLength="50"
          v-model="paymentOs.description" :markFormDirty="false" />
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12">
        <div class="text-right">
          <Button _key="btnSavePaymentOs" type="success" title="Salvar" :disabled="!isFormValid(formName)"
            classIcon="fas fa-save" size="medium" :clicked="savePaymentOs" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import InputDecimal from "@nixweb/nixloc-ui/src/component/forms/InputDecimal";
import DateTime from "@nixweb/nixloc-ui/src/component/forms/DateTime";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import CheckboxGroup from "@nixweb/nixloc-ui/src/component/forms/CheckboxGroup";
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";
import PaymentOs from "@/components/modules/maintenance/payment-os/PaymentOs.js";
import TypePaymentCreateUpdate from "../../finance/type-payment/TypePaymentCreateUpdate.vue";
import RadioGroup from "@nixweb/nixloc-ui/src/component/forms/RadioGroup";

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "PaymentOsCreateUpdate",
  components: {
    InputText,
    InputDecimal,
    DateTime,
    Select,
    Button,
    RadioGroup,
    CheckboxGroup,
    TypePaymentCreateUpdate,
  },
  props: {
    orderServiceId: String,
    status: Number
  },
  data() {
    return {
      formName: "paymentOsCreateUpdate",
      paymentOs: new PaymentOs(),
      urlCreate: "/api/v1/maintenance/payment-os/create",
      urlUpdate: "/api/v1/maintenance/payment-os/update",
    };
  },
  computed: {
    ...mapGetters("validation", ["isFormValid"]),
    ...mapGetters("generic", ["event"]),
    ...mapGetters("user", ["hasRule"]),
  },
  methods: {
    ...mapActions("generic", ["postApi", "putApi"]),
    ...mapMutations("generic", [
      "removeLoading",
      "removeEvent",
      "hideModal",
      "hideVodal",
    ]),
    ...mapMutations("validation", ["resetValidation"]),
    savePaymentOs() {
      this.paymentOs.orderServiceId = this.orderServiceId;
      this.paymentOs.status = this.status;

      if (this.paymentOs.id) {
        let params = { url: this.urlUpdate, obj: this.paymentOs };
        this.putApi(params).then((response) => {
          if (response.success) this.hideModal();
          this.removeLoading(["btnSavePaymentOs"]);
        });
      } else {
        let params = { url: this.urlCreate, obj: this.paymentOs };
        this.postApi(params).then((response) => {
          if (response.success) this.hideModal();
          this.removeLoading(["btnSavePaymentOs"]);
        });
      }
    },
    update(data) {
      this.paymentOs = new PaymentOs();
      this.paymentOs.update(data);
      this.removeLoading([data.id]);
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "paymentOsCreate") {
          this.resetValidation(this.formName);
          this.paymentOs = new PaymentOs();
        }
        if (event.name == "paymentOsUpdate") this.update(event.data);
      },
      deep: true,
    },
  },
};
</script>
