<template>
  <div class="side-by-side">
    <Dropdown title="Opções" type="primary" size="small" classIcon="fa-solid fa-chevron-down" right="50"
      :items="options" />

    <PrintPreview v-if="printRent" module="Maintenance" :orderServiceId="id" :onHideModal="closeModal" />

  </div>
</template>
<script>


import Dropdown from "@nixweb/nixloc-ui/src/component/forms/Dropdown.vue";
import PrintPreview from '../../adm/document/PrintPreview.vue';

import { mapMutations, mapGetters } from "vuex";

export default {
  name: "DropdownOptionsRent",
  components: {
    Dropdown,
    PrintPreview
  },
  props: {
    orderService: Object
  },
  data() {
    return {
      id: this.$route.params.id,
      printRent: false,
      options: [
        {
          title: "Compartilhar",
          showByStatus: [1, 2, 3],
          classIcon: "fa-sharp fa-regular fa-share-nodes",
          eventName: "printRent",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapMutations("generic", ["openModal", "addEvent", "hideModal", "removeLoading"]),
    closeModal() {
      this.printRent = false;
    }
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "printRent") {
          this.printRent = true;
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.select-document {
  min-height: 100px;
}
</style>