<template>
  <div>
    <ViewTemplateConfiguration :templateList="templateList" :showSearch="false" :showHorizontalFilter="false"
      :propsParam="propsParam">
      <div slot="content-buttons">
        <div class="div-btn-paymentRent-create">
          <Button v-if="showBtnCreate" _key="btnPaymentRentCreate" title="Pagamento" classIcon="fas fa-plus-circle"
            type="primary" size="small" :clicked="create" />
        </div>
      </div>
      <div slot="content-buttons-table-header">
        <Button v-if="showBtnLaunchFinance" _key="btnGenerateFinance" :title="'Lançar no Financeiro ' + selected.length"
          type="primary" size="small" :clicked="confirmGenerate" />
      </div>
    </ViewTemplateConfiguration>
    <Modal title="Tem certeza que deseja lançar?" :width="500" v-show="showModal('generateFinance')">
      <Alert type="warning">
        <span>
          Atenção, certifique-se que está lançando o valor correto, após lançado
          somente o Financeiro poderá alterar
        </span>
      </Alert>
      <Confirmation :isModal="false" title="Você tem certeza?" type="primary"
        :confirmed="createPaymentsAccountsReceivable" />
    </Modal>

    <Modal title="Pagamento" :width="700" :height="750" v-show="showModal('paymentOsCreateUpdate')">
      <PaymentOsCreateUpdate :status="status" :orderServiceId="orderServiceId" />
    </Modal>

  </div>
</template>

<script>

import ViewTemplateConfiguration from "@nixweb/nixloc-ui/src/component/template/ViewTemplateConfiguration";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Confirmation from "@nixweb/nixloc-ui/src/component/shared/Confirmation.vue";
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";

import PaymentOsCreateUpdate from './PaymentOsCreateUpdate.vue'

import { mapMutations, mapState, mapGetters, mapActions } from "vuex";

export default {
  name: "PaymentOsList",
  components: {
    ViewTemplateConfiguration,
    Button,
    Confirmation,
    Alert,
    Modal, PaymentOsCreateUpdate
  },
  props: {
    orderServiceId: String,
    status: Number,
    showBtnCreate: {
      type: Boolean,
      default: true,
    },
    showBtnLaunchFinance: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      templateList: {
        urlGetApi: "/api/v1/maintenance/payment-os/get-all",
        urlGenerateAllApi: "/api/v1/maintenance/payment-os/create-accounts-payments",
        urlDeleteAllApi: "/api/v1/maintenance/payment-os/delete",
        headerTable: [
          {
            field: "typePaymentName",
            title: "Tipo de Pagamento",
            type: "link",
            eventName: "paymentOsUpdate",
          },
          {
            field: "dueDate",
            title: "Vencimento",
            type: "text",
            classCssTitle: "text-center",
            classCssBody: "text-center",
          },
          {
            field: "value",
            title: "Valor",
            type: "currency",
            classCssTitle: "text-center",
            classCssBody: "text-center",
          },
          {
            field: "description",
            title: "Descrição",
            type: "text",
          },
          {
            field: "destinationName",
            title: "Tipo",
            type: "class",
            fieldComparison: "destinationName",
            classCssTitle: "text-center",
            classCssBody: [

              {
                classCss: "text-center badge badge-danger",
                fieldComparison: "Fornecedor",
              },
              {
                classCss: "text-center badge badge-primary",
                fieldComparison: "Cliente",
              },
            ],
          },
          {
            title: "Financeiro",
            field: "status",
            type: "html",
            classCssTitle: "text-center",
            classCssBody: "text-center",
          },
          {
            title: "",
            field: "iconTokenBillet",
            type: "html",
            classCssTitle: "text-center",
            classCssBody: "text-center",
          },
          {
            type: "button",
            typeButton: "default",
            classCssTitle: "text-center",
            classCssBody: "text-center",
            fieldComparison: "hasBillet",
            classCssBody: [
              {
                classCss: "hide-link",
                fieldComparison: "Não",
              },
            ],
            button: {
              classIcon: "fa-regular fa-copy",
              type: "default",
              size: "small",
              eventName: "copyLink",
            },
          },
        ],
      },
      propsParam: {
        any: this.orderServiceId,
      },
    };
  },
  computed: {
    ...mapGetters("generic", ["showModal", "event"]),
    ...mapState("generic", ["selected"]),
  },
  methods: {
    ...mapMutations("generic", ["openModal", "hideModal", "addEvent", "removeLoading"]),
    ...mapActions("generic", ["putAllApi"]),
    create() {
      this.openModal("paymentOsCreateUpdate");
      this.addEvent({ name: "paymentOsCreate" });
      this.removeLoading(["btnPaymentRentCreate"]);
    },
    confirmGenerate() {
      this.removeLoading(["btnGenerateFinance"]);
      this.openModal("generateFinance");
    },
    createPaymentsAccountsReceivable() {
      let params = {
        url: this.templateList.urlGenerateAllApi,
        selected: this.selected,
      };
      this.putAllApi(params).then((response) => {
        if (response.success) {
          this.hideModal("generateInvoice");
        }
        this.removeLoading(["confirm"]);
      });
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "paymentOsUpdate") {
          this.openModal("paymentOsCreateUpdate");
        }

        if (event.name == "copyLink") {
          const url = `https://app.boletocloud.com/boleto/2via/${event.data.tokenBillet}`;
          navigator.clipboard.writeText(url);
          this.$toasted.show("Link copiado...", { type: "info" });
        }
      },
      deep: true,
    },
  },

};
</script>
<style>
.badge-default {
  background-color: #eeeeee;
  font-size: 13px !important;
}

.box-finance {
  width: 10px;
  height: 10px;
  border-radius: 20px;
  margin-right: 8px;
}

.finance-delay {
  background-color: red;
}

.finance-paid {
  background-color: #009183;
}

.finance-today {
  background-color: #ff8a1b;
}

.finance-scheduled {
  background-color: #3d4eae;
}

.payable {
  color: red;
}

.receivable {
  color: darkblue;
}

.div-btn-paymentRent-create {
  margin-bottom: 15px;
}

.hide-link {
  display: none;
}
</style>