<template>
    <div>
        <Button _key="btnItemsOrderServiceCreate" title="Produto" classIcon="fa-solid fa-circle-plus" type="primary"
            size="small" :clicked="create" />

        <Button _key="btnSearchBarCode" title="Leitor Óptico" classIcon="fa-solid fa-barcode-read" type="primary"
            size="small" :clicked="searchBarCode" />

        <div v-for="item in content.data" :key="item.id">
            <ItemsOrderServiceItem :item="item" />
        </div>
        <div class="container-no-data" v-if="content.data.length == 0">
            <Molded>
                Nenhum produto adicionado!
            </Molded>
        </div>
        <Modal title="Produto" :width="1000" v-show="showModal('itemOrderService')">
            <ItemsOrderServiceCreateUpdate v-show="showModal('itemOrderService')" :orderServiceId="orderServiceId" />
        </Modal>
        <Modal title="Leitor Óptico" :width="800" v-show="showModal('searchBarCode')">
            <b-row>
                <b-col sm="3">
                    <DateTime title="Inicio" field="startDateHour" format="DD/MM/YYYY" type="date" placeholder
                        :markFormDirty="false" :required="true" v-model="dateStart" />
                </b-col>
                <b-col sm="3">
                    <DateTime title="Fim (Previsão)" field="endDateHour" format="DD/MM/YYYY" type="date" placeholder
                        :markFormDirty="false" :required="true" v-model="dateEnd" />
                </b-col>
            </b-row>
            <SearchByBarcode :urlGet="urlGetBarCode" :params="{ statusPatrimony: 1 }" />
        </Modal>

    </div>
</template>

<script>

import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import DateTime from "@nixweb/nixloc-ui/src/component/forms/DateTime";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import ScrollBar from "@nixweb/nixloc-ui/src/component/layout/ScrollBar.vue";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";
import SearchByBarcode from '../../stock/search-product/SearchByBarcode.vue'

import ItemsOrderServiceItem from './ItemsOrderServiceItem.vue';
import ItemsOrderServiceCreateUpdate from './ItemsOrderServiceCreateUpdate.vue';

import { mapMutations, mapActions, mapGetters } from "vuex";

export default {
    name: "ItemsOrderServiceList",
    props: {
        orderServiceId: String,
    },
    components: {
        Button, Modal, DateTime, ScrollBar, ItemsOrderServiceCreateUpdate, ItemsOrderServiceItem, Molded, SearchByBarcode
    },
    data() {
        return {
            urlGetApi: "/api/v1/maintenance/items-os/get-all",
            urlDelete: "/api/v1/maintenance/items-os/delete",
            urlGetBarCode: "/api/v1/moviment/generate-moviment/get-by-barcode",
            content: {
                data: [],
                totalRecords: 0,
            },
            loading: false,
            dateStart: "",
            dateEnd: "",

        };
    },
    mounted() {
        this.getAll();
    },
    computed: {
        ...mapGetters("generic", ["showModal", "event"]),
    },
    methods: {
        ...mapMutations("generic", ["openModal", "addEvent", "removeLoading"]),
        ...mapActions("generic", ["getApi", "deleteAllApi"]),
        getAll() {
            this.loading = true;
            let params = {
                url: this.urlGetApi,
                obj: {
                    any: this.orderServiceId,
                },
            };
            this.getApi(params).then((response) => {
                this.content.data = response.content.data;
                this.content.totalRecords = response.content.totalRecords;
                this.loading = false;
            });
        },
        create() {
            this.openModal("itemOrderService");
            this.addEvent({ name: "createItemsOrderService" });
            this.removeLoading(["btnItemsOrderServiceCreate"]);
        },
        searchBarCode() {
            this.openModal("searchBarCode");
            this.removeLoading(["btnSearchBarCode"]);
        }
    },
    watch: {
        event: {
            handler(event) {
                if (event.name == "saveItemsOrderService") {
                    this.getAll();
                }

                if (event.name == "updateItemOrderService") {
                    this.openModal("itemOrderService");
                }

                if (event.name == "executedSearchByBarcode") {
                    let data = { dateStart: this.dateStart, dateEnd: this.dateEnd, product: event.data };
                    this.addEvent({ name: "executedSearchByBarcodeOrderService", data: data });
                }
            },
            deep: true,
        },
    },
}
</script>
<style scoped>
.container-no-data {
    margin-top: 20px;
}
</style>