export default class ItemsOrderService {
    constructor() {
        this.id = "";
        this.type = 1;
        this.orderServiceId = "";
        this.patrimony = { id: "", content: "" };
        this.typeProduct = { id: "", content: "" };
        this.product = { id: "", content: "" };
        this.quantity = 1;
        this.description = "";
        this.dateStart = "";
        this.dateEnd = "";
        this.status = 1;
        this.statusItem = 1;
        this.valueCustomer = 0;
        this.valueSupplier = 0;
        this.inactivate = false;
        this.inactivateOld = false;
    }
    update(data) {
        this.id = data.id;


        if (data.patrimony.id) {
            this.patrimony = { id: data.patrimony.id, content: data.patrimony.content };
            this.type = 1;
        } else {
            this.typeProduct = { id: data.typeProduct.id, content: data.typeProduct.content };
            this.product = { id: data.product.id, content: data.product.content };
            this.type = 2;
        }

        this.quantity = data.quantity;
        this.description = data.description;
        this.dateStart = data.dateStartStr;
        this.dateEnd = data.dateEndStr;
        this.status = data.status;
        this.statusItem = data.statusItem;
        this.valueCustomer = data.valueCustomer;
        this.valueSupplier = data.valueSupplier;
        this.inactivate = data.inactivate;
        this.inactivateOld = data.inactivate;
    }
}